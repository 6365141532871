import { useTilesContext } from "./tiles_context";
import './preview.css'; // Make sure to create this CSS file in the same directory

const StructurePreview = () => {
    const { images } = useTilesContext();
    return (
        <div>
          
            <div>
                {Object.keys(images).sort().map((folder, index) => ( // Sort folders to maintain order
                    <div key={index} className="folder-row">
                        <h2>{folder}</h2>
                        <div className="image-row">
                            {images[folder].map((file, fileIndex) => (
                                <div key={fileIndex} className="image-container">
                                    <img src={file.url} alt={file.name} style={{maxWidth: '100%', maxHeight: '200px'}}/>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>

    );
}

export default StructurePreview;
