
import React, {useState} from 'react';
import '../../App.css'
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Home, Search, AddCircleOutline, Notifications, Person } from '@mui/icons-material';
import SportsEsportsRoundedIcon from '@mui/icons-material/SportsEsportsRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import { Home as HomeIcon, Gamepad as GamepadIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './BottomNavBar.css';
import { useSidebarContext } from '../Sidebar/sidebar_context'; // Import the context
import Profile from "../../Pages/Settings/user_profile";
import Gamelist from "./GamePage";
import Drawer from '@mui/material/Drawer';




const BottomNavBar = () => {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const { structuredPages, authenticated } = useSidebarContext(); // Access structuredPages
    const [currentSubpages, setCurrentSubpages] = useState({});
    const [openSubpageMenu, setOpenSubpageMenu] = useState(false);














    const handleChange = (event, newValue) => {
        setValue(newValue);
        // Navigate to the corresponding route
        switch (newValue) {
            case 0:
                navigate('/');
                break;
            case 1:
                // Gamelist();
                navigate('/Games');
                break;
            case 2:
                navigate('/Notifications');
                break;
            case 3:
                navigate('/Statistics');
                break;
            case 4:
                navigate('/profile');
                break;
            default:
                break;
        }
    };

    // const navigationLinks = [
    //     { label: 'Home', icon: <HomeIcon />, path: '/' },
    // ];

    // if (authenticated && structuredPages) {
    //     Object.keys(structuredPages).forEach((pageKey) => {
    //         if (pageKey.toLowerCase() === 'administration') {
    //             return; // Skip 'administration' page
    //         }
    //         navigationLinks.push({
    //             label: pageKey,
    //             icon: <GamepadIcon />, // Use appropriate icons
    //             path: `/${pageKey}`,
    //             subpages: structuredPages[pageKey].subpages || {},
    //         });
    //     });
    // }

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    //     const selectedLink = navigationLinks[newValue];
    //
    //     if (Object.keys(selectedLink.subpages).length > 0) {
    //         // If the game has subpages, show a menu or modal
    //         setCurrentSubpages(selectedLink.subpages);
    //         setOpenSubpageMenu(true);
    //     } else {
    //         // Navigate to the game's main page
    //         navigate(selectedLink.path);
    //     }
    // };


    

    return (
        <BottomNavigation className="mobile-navigation"  value={value} onChange={handleChange} showLabels>
            <BottomNavigationAction  icon={<Home className="mobile-nav-icon" />} />
            <BottomNavigationAction  icon={<SportsEsportsRoundedIcon className="mobile-nav-icon" />} />
            {/*<BottomNavigationAction label="Add" icon={<AddCircleOutline />} />  // if the add games function comes to the cmc  */}
            <BottomNavigationAction  icon={<BarChartRoundedIcon className="mobile-nav-icon" />} />
            <BottomNavigationAction  icon={<Notifications className="mobile-nav-icon"  />} />

            {/*<BottomNavigationAction  icon={<Person className="mobile-nav-icon" />} >*/}
                <Profile/>
            {/*</BottomNavigationAction>*/}
        </BottomNavigation>
    );
};

export default BottomNavBar;
