import React, { useState } from "react";
import "./connections.css";

export const Connections = () => {
    const [parsedData, setParsedData] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    // New states for date and name
    const [selectedDate, setSelectedDate] = useState("");
    const [name, setName] = useState("");

    // --- 1) Handle file change and parse the file. ---
    function handleFileChange(e) {
        const file = e.target.files[0];
        if (!file) return;

        setSelectedFile(file); // Update selected file state

        const reader = new FileReader();
        reader.onload = function (event) {
            try {
                // 1) Split file content by lines
                const text = event.target.result;
                const lines = text.split(/\r?\n/).map((line) => line.trim()).filter(Boolean);

                // 2) First line is puzzleId
                const puzzleId = lines[0];

                // 3) Next 4 lines => Starting Grid
                const gridLines = lines.slice(1, 5);
                const startingGrid = gridLines.map((line) => {
                    return line.split(/\t| {2,}/).map((cell) => cell.trim());
                });

                // 4) Next 16 lines => group assignments
                const groupAssignments = lines.slice(5, 21).map((line) => {
                    const parts = line.split(/\s+/, 2);
                    const groupNum = parts[0];
                    const word = parts[1];
                    return { groupNum, word };
                });

                // 5) Next 4 lines => group themes
                const themeLines = lines.slice(21, 25);
                const groupThemes = {};
                themeLines.forEach((line) => {
                    const spaceIndex = line.indexOf("\t");
                    const grp = line.substring(0, spaceIndex);
                    const theme = line.substring(spaceIndex + 1).trim();
                    groupThemes[grp] = theme;
                });

                // 6) Build group objects
                const groupsMap = {};
                for (let i = 0; i < groupAssignments.length; i++) {
                    const { groupNum, word } = groupAssignments[i];
                    if (!groupsMap[groupNum]) {
                        groupsMap[groupNum] = [];
                    }
                    groupsMap[groupNum].push(word);
                }

                const groups = Object.keys(groupsMap)
                    .sort()
                    .map((groupNumber) => ({
                        number: groupNumber,
                        theme: groupThemes[groupNumber] || "",
                        words: groupsMap[groupNumber],
                    }));

                // 7) Construct final parsedData object
                const finalData = {
                    puzzleId,
                    "starting grid": startingGrid,
                    groups,
                    // Add empty placeholders for name and date to keep them in parsedData
                    name: "",
                    date: "",
                };

                setParsedData(finalData);
            } catch (err) {
                setParsedData({ error: "Parsing error: " + err.message });
            }
        };

        reader.readAsText(file);
    }

    // --- 2) Keep name and date updated in the component state and in parsedData. ---
    function handleDateChange(e) {
        const newDate = e.target.value;
        setSelectedDate(newDate);

        // Also update parsedData if it exists
        setParsedData((prevData) => {
            if (!prevData || prevData.error) return prevData;
            return { ...prevData, date: newDate };
        });
    }

    function handleNameChange(e) {
        const newName = e.target.value;
        setName(newName);

        // Also update parsedData if it exists
        setParsedData((prevData) => {
            if (!prevData || prevData.error) return prevData;
            return { ...prevData, name: newName };
        });
    }

    // --- 3) Handle the "Upload" button click. ---
    function handleUpload() {
        // Only proceed if both name and date are set (non-empty).
        if (!name.trim() || !selectedDate.trim()) {
            alert("Please enter both a Name and a Date before uploading.");
            return;
        }

        // At this point, parsedData should already have the updated name and date.
        // Make a POST request to your API endpoint.
        fetch("https://example.com/api/your-endpoint", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(parsedData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to upload data. Server responded with an error.");
                }
                return response.json();
            })
            .then((data) => {
                console.log("Data successfully uploaded:", data);
                alert("Data successfully uploaded!");
            })
            .catch((err) => {
                console.error(err);
                alert("An error occurred: " + err.message);
            });
    }

    return (
        <div>
            <div className="connections" style={{ width: "50%", float: "left" }}>
                {/* Custom File Input */}
                <div className="custom-file-input-container" style={{ textAlign: "center", marginBottom: "1rem" }}>
                    <label htmlFor="file-upload" className="custom-file-label">
                        Choose File
                    </label>
                    <input
                        id="file-upload"
                        type="file"
                        accept=".txt"
                        onChange={handleFileChange}
                        className="custom-file-input"
                    />
                    {selectedFile && <span className="selected-file">{selectedFile.name}</span>}
                </div>

                {parsedData && !parsedData.error && (
                    <>
                        <div className="challenge-preview">
                            <h3 className="title">Starting Grid</h3>
                            <div className="puzzle-grid">
                                {parsedData["starting grid"].map((row, rowIndex) =>
                                    row.map((cell, cellIndex) => (
                                        <div className="puzzle-cell" key={`${rowIndex}-${cellIndex}`}>
                                            {cell}
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>

                        <h3 className="title">Solution Preview</h3>
                        <div className="solution-preview">
                            {parsedData.groups &&
                                parsedData.groups.map((grp, index) => (
                                    <div
                                        className={`solution-group ${
                                            index === 0
                                                ? "group-yellow"
                                                : index === 1
                                                    ? "group-blue"
                                                    : index === 2
                                                        ? "group-green"
                                                        : "group-purple"
                                        }`}
                                        key={index}
                                    >
                                        <p>
                                            <strong>Theme {grp.number}:</strong> {grp.theme}
                                        </p>
                                        <ul>
                                            {grp.words.map((word, wIndex) => (
                                                <li key={wIndex}>{word}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                        </div>
                    </>
                )}

                {parsedData && parsedData.error && (
                    <div className="error-message">
                        <strong>Error:</strong> {parsedData.error}
                    </div>
                )}

                {/* ---- Conditionally Rendered Upload Section with new fields ---- */}
                {parsedData && !parsedData.error && (
                    <div className="upload-button-container">
                        <label htmlFor="date-field" style={{ marginRight: "0.5rem" }}>
                            Select Date:
                        </label>
                        <input
                            type="date"
                            id="date-field"
                            value={selectedDate}
                            onChange={handleDateChange}
                            style={{ marginRight: "1rem" }}
                        />

                        <label htmlFor="name-field" style={{ marginRight: "0.5rem" }}>
                            Name:
                        </label>
                        <input
                            type="text"
                            id="name-field"
                            placeholder="Challenge Name"
                            value={name}
                            onChange={handleNameChange}
                            style={{ marginRight: "1rem" }}
                        />

                        <button onClick={handleUpload}>Upload</button>
                    </div>
                )}
            </div>

            <div className="connections" style={{ width: "50%", float: "right" }}>
                {/* Display the entire parsedData object for debugging */}
                {parsedData && <pre>{JSON.stringify(parsedData, null, 2)}</pre>}
            </div>
        </div>
    );
};

export default Connections;
