import React, {useEffect, useState} from 'react';
import './statistics_header.css';
import {useSidebarContext} from "../Sidebar/sidebar_context";
// import Tooltip from '@mui/material/Tooltip';
import LoadingScreenDenda from "../Unique/Animation/loading_screen";

const fetchData = async (game_id, retryCount = 3) => {
    for (let i = 0; i < retryCount; i++) {
        try {
            if (game_id === null) return;
            const response = await fetch(process.env.REACT_APP_API_URL_STATISTICS + `?method=getHeaderStatisticsNew&gameid=${game_id}`, {
                credentials: 'include',
                method: "GET",
            });
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            // console.log("Data:", data);
            // console.log("game_id: ", game_id);
            return data;
        } catch (error) {
            console.error('Failed to fetch data:', error.message);
            // If fetch fails, and it's not the last attempt, wait a bit before retrying
            if (i < retryCount - 1) {
                await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds
            } else {
                // If it's the last attempt, rethrow the error
                throw error;
            }
        }
    }
};

const PageStatisticsHeader = ({game_id}) => {
    const {setAuthenticated, mode} = useSidebarContext();
    const [gameplays, setGameplays] = useState([0, 0, 0, 0]);
    const [ads, setAds] = useState([0, 0, 0, 0]);
    // const [, setPeriods] = useState(["Today", "Yesterday", "Current Month", "Last Month"]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const controller = new AbortController(); // Create a new instance of AbortController
        const {signal} = controller; // Get the signal from the controller

        function ensureNumber(value) {
            return isNaN(value) || value == null ? 0 : value;
        }

        const fetchDataWithAbort = async () => {
            if (game_id === null) return;
            try {
                setLoading(true);
                const response = await fetch(process.env.REACT_APP_API_URL_STATISTICS + `?method=getHeaderStatisticsNew&gameid=${game_id}`, {
                    signal,
                    credentials: 'include',
                });
                if (response.status === 401) {
                    setAuthenticated(false);
                }
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                // Check if the fetch was aborted to avoid setting state on an unmounted component
                if (!signal.aborted) {
                    // console.log('THE TYPE OF ADS TODAY:');
                    // console.log(typeof data['Ads Today']);
                    // let ads_this_month_calc = parseInt(data['Ads Current Month'])+parseInt(data['Ads Today']);
                    // let gp_this_month_calc = parseInt(data['Gameplays Current Month'])+parseInt(data['Gameplays Today']);
                    let ads_this_month = (ensureNumber(parseInt(data['Ads Current Month'])) + ensureNumber(parseInt(data['Ads Today']))).toString(); //
                    let gp_this_month = (ensureNumber(parseInt(data['Gameplays Current Month'])) + ensureNumber(parseInt(data['Gameplays Today']))).toString(); //
                    setGameplays([data['Gameplays Today'], data['Gameplays Yesterday'], gp_this_month, data['Gameplays Last Month']] || []);
                    setAds([data['Ads Today'], data['Ads Yesterday'], ads_this_month, data['Ads Last Month']] || []);
                    // setPeriods(data['period']);
                    setLoading(false);
                }
            } catch (error) {
                if (!signal.aborted) {
                    console.error('Failed to fetch data:', error);
                    setLoading(true);
                    // Handle other errors, e.g., network error, json parsing error
                }
            }
        };

        fetchDataWithAbort();

        return () => {
            controller.abort(); // Abort the fetch request on cleanup
        };
    }, [game_id]); // Dependency on game_id


    if (loading) {
        return <LoadingScreenDenda text={"Loading"}/>; // Render a loading component or null
    }

    return (
        <>
            <div className={`dashboard `}>
                <div className="metrics">
                    {/* Using optional chaining (?.) to access elements and providing a fallback value */}
                    <div className="metric">
                        <h2>Gameplay Today</h2>
                        <p>{gameplays?.[0] ? Intl.NumberFormat('de-DE').format(gameplays[0]) : '...'}</p>
                    </div>
                    <div className="metric">
                        <h2>Gameplay Yesterday</h2>
                        <p>{gameplays?.[1] ? Intl.NumberFormat('de-DE').format(gameplays[1]) : '...'}</p>
                    </div>
                    <div className="metric">
                        <h2>Gameplay This Month</h2>
                        <p>{gameplays?.[2] ? Intl.NumberFormat('de-DE').format(gameplays[2]) : '...'}</p>
                    </div>
                    <div className="metric">
                        <h2>Gameplay Previous Month</h2>
                        <p>{gameplays?.[3] ? Intl.NumberFormat('de-DE').format(gameplays[3]) : '...'}</p>
                    </div>
                    <div className="metric">
                        <h2>Ads Today</h2>
                        <p>{ads?.[0] ? Intl.NumberFormat('de-DE').format(ads[0]) : '...'}</p>
                    </div>
                    <div className="metric">
                        <h2>Ads Yesterday</h2>
                        <p>{ads?.[1] ? Intl.NumberFormat('de-DE').format(ads[1]) : '...'}</p></div>
                    <div className="metric">
                        <h2>Ads This Month</h2>
                        <p>{ads?.[2] ? Intl.NumberFormat('de-DE').format(ads[2]) : '...'}</p></div>
                    <div className="metric">
                        <h2>Ads Previous Month</h2>
                        <p>{ads?.[3] ? Intl.NumberFormat('de-DE').format(ads[3]) : '...'}</p></div>
                </div>
            </div>
            <hr/>
        </>
    );

};

export default PageStatisticsHeader;