// Home.js component

import ReactApexChart from "react-apexcharts";
import "../../Components/Unique/Login/login_form.css";
import LoginForm from "../../Components/Unique/Login/login_form"; // Importing LoginForm component
import PageHeader from "../../Components/Page/page_header"; // Importing PageHeader component
import {useEffect, useState, useRef} from "react"; // Importing React hooks
import {useSidebarContext} from "../../Components/Sidebar/sidebar_context"; // Context for managing sidebar state
import logo from './../../Components/Assets/denda white.png'; // Importing logo image
import phone_top from '../../Components/Assets/phone_top.png'; // Importing top phone image
import phone_mid from '../../Components/Assets/phone_mid.png'; // Importing middle phone image
import phone_low from '../../Components/Assets/phone_low.png'; // Importing bottom phone image
import stonks_mini from '../../Components/Assets/stonks_mini.png'; // Importing mobile version of stonks image
import "./home.css"; // Importing specific styles for Home page
import {MoveUp} from 'lucide-react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';





const Home = () => {

    const percentageRef = useRef(null);



    function Dashboard() {
        const [series, setSeries] = useState([
            {
                name: 'Series1',
                data: [31, 40, 28, 51, 42, 109, 100],
                color: '#40E0D0'
            },
            {
                name: 'Series2',
                data: [11, 32, 45, 32, 34, 52, 41],
                color: '#FFC107'
            },
        ]);

        const [chartOptions, setChartOptions] = useState({
            chart: {
                // id: 'gameplays-bar',
            },
            xaxis: {
                categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            },
            title: {
                text: 'Gameplay Stats',
                align: 'center',
            },
            stroke: {
                curve: 'smooth',
            },
            dataLabels: {
              enabled: false,
            },
        });

        return (
            <div className="dashb-container">
                <div className="dashb">
                    <ReactApexChart
                        options={chartOptions}
                        series={series}
                        type="area"
                        height={425}
                        width={1705}
                    />
                </div>
            </div>
        );
    }



    function Kpi({ children, header, number, percentage }) {
        const [ref, inView] = useInView({
            triggerOnce: true,
            threshold: 0.1,
        });

        useEffect(() => {
            if (inView && percentageRef.current) {
                // Dynamically set the CSS variable for the percentage
                percentageRef.current.style.setProperty('--num', Math.abs(percentage));
            }
        }, [inView, percentage]);


        return (
            <div className="kpi" ref={ref}>
                <span>{header}</span>
                <h4 className="number">
                    {inView ? (
                        <CountUp end={number} duration={2} separator="," />
                    ) : (
                        0
                    )}
                </h4>
                <h2 className={`percentage ${percentage >= 0 ? 'green' : 'red'}`} ref={percentageRef}>
                    <MoveUp />
                    {inView ? (
                        <CountUp end={percentage} duration={2} separator="," />
                    ) : (
                        0
                    )}
                </h2>
                {children}
            </div>
        );
    }

    function Games({children}) {

        return (
            <div className="topg-container">

                <div className="topg">
                    <h4> Top Games </h4>
                    {children}
                </div>

                <div className="topg">
                    <h4> Bottom Games </h4>
                    {children}
                </div>

            </div>
        )
    }

    // States for controlling the visibility of welcome message and login form
    const [showWelcome, setShowWelcome] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(false);

    // Getting the authentication and sidebar functions from context
    const {authenticated, setSidebarOpen, inverse} = useSidebarContext();
    const {toggleMobileSidebar} = useSidebarContext();

    // State for controlling the mobile sidebar toggle
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

    // useEffect to trigger animations on component mount
    useEffect(() => {
        // Show welcome message when component mounts
        setShowWelcome(true);

        // Delay showing the login form for animation effect
        const timer = setTimeout(() => setShowLoginForm(true), 500); // Adjusting the delay for the form

        // Clear the timer when the component is unmounted
        return () => clearTimeout(timer);
    }, []);

    // useEffect to handle authentication changes
    useEffect(() => {
        if (authenticated) {
            // Hide welcome message and login form if authenticated
            setShowWelcome(false);
            setShowLoginForm(false);
            setSidebarOpen(true); // Open sidebar when user is authenticated
        } else {
            // Show welcome message and login form if not authenticated
            setShowWelcome(true);
            setShowLoginForm(true);
        }
    }, [authenticated, setSidebarOpen]); // Effect runs when authentication state or sidebar state changes

    return (
        <div className="home"> {/* Controlling overflow behavior of the home page */}
            {authenticated ? ( // Check if the user is authenticated
                <>
                    {/* Show different background images based on screen size */}
                    {/*<img className="hide-mobile background-image" src={stonks_mini}*/}
                    {/*     alt="Stonks"/> /!* Display only on desktop *!/*/}

                    <div className={"default_page_style"}>
                        <PageHeader title="Home"
                                    toggleSidebar={toggleMobileSidebar}/> {/* Render page header and toggle sidebar */}


                        {/*<div className="kpi-container">*/}
                        {/*    <Kpi header={"New Players"} number={572} percentage={3.19}/>*/}
                        {/*    <Kpi header={"Total Players"} number={1738} percentage={5.25}/>*/}
                        {/*    <Kpi header={"Gameplays"} number={12310} percentage={3.19}/>*/}
                        {/*    <Kpi header={"AVG Gameplays"} number={356} percentage={9.43}/>*/}
                        {/*    <Kpi header={"ADS"} number={7430} percentage={1.02}/>*/}
                        {/*</div>*/}

                        {/*<Dashboard>*/}

                        {/*</Dashboard>*/}

                        {/*<Games>*/}

                        {/*</Games>*/}



                        <div className="welcome-message">
                            <p className="message">
                                <span className="welcome-quote">“</span>
                                <span>{("Welcome to the ")} </span>
                                <span className="welcome-bold">{("Denda Content Management Console")}</span>
                                <span>{(", where seamless control meets boundless creativity.")}</span>
                                <span className="welcome-quote">”</span>
                            </p>
                        </div>


                        {/* Phone images only displayed on desktop */}
                        <div className="d-none d-md-block">
                            <img className="phone-image phone-image-top" src={phone_top}
                                 alt="Phone"/> {/* Top section of phone */}
                            <img className="phone-image phone-image-middle" src={phone_mid}
                                 alt="Phone"/> {/* Middle section of phone */}
                            <img className="phone-image phone-image-bottom" src={phone_low}
                                 alt="Phone"/> {/* Bottom section of phone */}
                        </div>
                    </div>
                </>
            ) : ( // If not authenticated, show login form
                <div className="login-page">
                    <div className="section1"> {/* New container */}
                        <div className="denda">
                            <h2> Denda </h2>
                        </div>
                        <div className="login-logo">
                            <img src={logo} alt="Logo" className="logo"/> {/* Display company logo */}
                        </div>

                        {showWelcome && ( // Show welcome message before login form
                            <div className="welcome-message-login">
                                <h4> Welcome to the Denda Content Management Console </h4>
                            </div>
                        )}
                    </div>
                    <div className="section2">
                        {showLoginForm && ( // Show login form after animation delay
                            <>
                                <span className="log-in"> Log In </span>
                                <div className="login-form">
                                    <LoginForm/> {/* Render LoginForm component */}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Home;
