import React, {useEffect, useRef, useState} from 'react'; // Import React and hooks
import {useNonogramContext} from "./nonogram_context"; // Custom context for Nonogram data
import Button from '@mui/material/Button'; // Material-UI components for UI elements
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText'; // Import for dialog text
import TextField from '@mui/material/TextField'; // Import for date input field
import {useAlertContext} from "../../../Page/alert_context"; // Context for alert messages
import {useSidebarContext} from "../../../Sidebar/sidebar_context";
import NonogramGenerator from "./nonogram_generator";
import "./nonogram.css";

const ExistingNonogramViewer = ({ game_id }) => {
    const { setAuthenticated } = useSidebarContext(); // Function to handle authentication status
    // State to store nonograms categorized by their status
    const [nonograms, setNonograms] = useState({
        scheduled: [],
        available: [],
        used: [],
        rejected: []
    });
    const [activeTab, setActiveTab] = useState('scheduled'); // State to track the active tab
    // Destructure functions and data from Nonogram context
    const {
        setSelectedNonogramInfo,
        newUploadedNono
    } = useNonogramContext();
    const { showAlert } = useAlertContext(); // Function to display alert messages

    // State variables to control dialog visibility
    const [openRescheduleDialog, setOpenRescheduleDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openSelectDialog, setopenSelectDialog] = useState(false);
    const [rescheduleDate, setRescheduleDate] = useState(""); // State for reschedule date input
    const [currentNonogram, setCurrentNonogram] = useState(null); // State to hold the currently selected nonogram
    const [changeNonogram, setChangeNonogram] = useState(false); // Trigger for updating nonogram data
    const [method, setMethod] = useState(""); // Method to determine the type of action to perform
    const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

    // Custom fetch function for GET requests
    async function callFetch1(url, signal) {
        return await fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Connection': 'close',
            },
            signal
        });
    }

    // Function to update the challenge date of a nonogram
    async function updateChallenge(game_id, rescheduleDate, name) {
        await fetch(process.env.REACT_APP_API_URL_STATISTICS, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                method: "updateChallengeDate",
                nonogram: name,
                date: rescheduleDate,
                gameid: game_id
            })
        });
    }

    // Function to update the status of a nonogram challenge
    async function updateChallengeStatus(name, game_id, status) {
        await fetch(process.env.REACT_APP_API_URL_STATISTICS, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                method: "updateChallengeStatus",
                nonogram: name,
                status: status,
                gameid: game_id
            })
        });
    }

    // Function to delete a nonogram challenge
    async function deleteChallenge(name, game_id) {
        await fetch(process.env.REACT_APP_API_URL_STATISTICS, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                method: "deleteChallenge",
                nonogram: name,
                gameid: game_id
            })
        });
    }

    // Function to check the response from a fetch call
    function checkResponse (response) {
        if (!response.ok) {
            if (response.status === 401) {
                setAuthenticated(false); // Update authentication status if unauthorized
            }
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    }

    // useEffect hook to fetch nonogram data when game_id changes
    const fetchNonograms = async () => {
        if (game_id === null) return;
        const controller = new AbortController();
        const { signal } = controller;
        try {
            const url = process.env.REACT_APP_API_URL_STATISTICS + '?method=getDailyChallengeNonogram&gameid=' + game_id;
            const response = await callFetch1(url, signal);
            checkResponse(response);
            const rawData = await response.json();
            const dataArray = Array.isArray(rawData) ? rawData : [rawData];
            const data = dataArray.reduce((acc, { name, bw, status, date }) => {
                if (!acc[status]) {
                    acc[status] = [];
                }
                acc[status].push({ name, data: bw, date });
                return acc;
            }, {});
            if (!signal.aborted) {
                setNonograms(data);
            }
        } catch (error) {
            if (!signal.aborted) {
                console.error("Failed to fetch data: ", error);
            }
        }
    };

    // Function to draw a nonogram on a canvas element
    const drawNonogram = (canvas, nonogramData) => {
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        const size = Math.sqrt(nonogramData.length); // Calculate grid size
        const cellSize = 5; // Define the size of each cell
        canvas.width = size * cellSize;
        canvas.height = size * cellSize;

        nonogramData.forEach((cell, index) => {
            const x = index % size;
            const y = Math.floor(index / size);
            ctx.fillStyle = cell === '1' ? 'black' : 'white'; // Fill cell based on value
            ctx.fillRect(x * cellSize, y * cellSize, cellSize, cellSize);
        });
    };

    // canvas for the color version of the image
    const colorCanvasRef = useRef(null);

    // Function to update nonogram state after performing an action
    const updateNonograms = (newStatus) => {
        let name = currentNonogram.name;
        let newDate;
        if (newStatus === 'scheduled') {
            newDate = rescheduleDate;
        } else {
            newDate = currentNonogram.date;
        }
        let data = currentNonogram.data;
        setNonograms(prevState => {
            // Create copies of the arrays to avoid mutating state directly
            let newScheduled = Array.isArray(prevState.scheduled) ? [...prevState.scheduled] : [];
            let newAvailable = Array.isArray(prevState.available) ? [...prevState.available] : [];
            let newUsed = Array.isArray(prevState.used) ? [...prevState.used] : [];
            let newRejected = Array.isArray(prevState.rejected) ? [...prevState.rejected] : [];

            // Remove the nonogram from all arrays
            if (newScheduled.length !== 0) newScheduled = newScheduled.filter(nonogram => nonogram.name !== name);
            if (newAvailable.length !== 0) newAvailable = newAvailable.filter(nonogram => nonogram.name !== name);
            if (newUsed.length !== 0) newUsed = newUsed.filter(nonogram => nonogram.name !== name);
            if (newRejected.length !== 0) newRejected = newRejected.filter(nonogram => nonogram.name !== name);

            // Create a new nonogram object with updated status
            const updatedNonogram = { name: name, date: newDate, status: newStatus, data: data };

            // Add the nonogram to the appropriate array based on new status
            switch (newStatus) {
                case 'scheduled':
                    newScheduled.push(updatedNonogram);
                    break;
                case 'available':
                    newAvailable.push(updatedNonogram);
                    break;
                case 'used':
                    newUsed.push(updatedNonogram);
                    break;
                case 'rejected':
                    newRejected.push(updatedNonogram);
                    break;
            }

            // Return the updated state
            return {
                scheduled: newScheduled,
                available: newAvailable,
                used: newUsed,
                rejected: newRejected
            };
        });
    };

    // useEffect hook to handle new uploaded nonograms
    useEffect(() => {
        if (game_id === null) return;
        if (newUploadedNono.gameid !== game_id) return; // Ensure the nonogram belongs to the current game
        setNonograms(prevState => {
            let newAvailable = Array.isArray(prevState.available) ? [...prevState.available] : [];

            const updatedNonogram = {
                name: newUploadedNono.name,
                date: newUploadedNono.newDate,
                status: 'available',
                data: newUploadedNono.data
            };

            newAvailable.push(updatedNonogram); // Add new nonogram to available list

            return {
                scheduled: prevState.scheduled,
                available: newAvailable,
                used: prevState.used,
                rejected: prevState.rejected
            };
        });
    }, [newUploadedNono]);

    useEffect(() => {
        fetchNonograms();
    }, [game_id]);

    // Function to remove a nonogram from the 'rejected' list after deletion
    const deleteNonogramLive = () => {
        let name = currentNonogram.name;
        setNonograms(prevState => {
            let newRejected = prevState.rejected.filter(nonogram => nonogram.name !== name);
            return { ...prevState, rejected: newRejected };
        });
    };

    // useEffect hook to handle nonogram changes based on 'method' and 'changeNonogram' state
    useEffect(() => {
        if (game_id === null) return;
        if (!changeNonogram) return;
        const changeChallengePost = async () => {
            const name = currentNonogram.name;
            switch (method) {
                case "changedate": {
                    // Check if scheduling is allowed on the selected date
                    const response = await fetch(process.env.REACT_APP_API_URL_STATISTICS + "?method=getAllowedToScheduleNonogram&gameid=" + game_id + "&date=" + rescheduleDate, {
                        method: 'GET',
                        credentials: 'include',
                    });
                    const scheduleAllowedData = await response.json();
                    if (scheduleAllowedData.result === 0) {
                        showAlert(<span>Unable to schedule: The selected date either already has a Nonogram or is in the past.</span>, "error");
                        return;
                    }
                    // Update challenge date and status
                    await updateChallenge(game_id, rescheduleDate, name);
                    await updateChallengeStatus(name, game_id);
                    showAlert(<span>Successfully changed date to {rescheduleDate}!</span>, "success");
                    updateNonograms("scheduled");
                    break;
                }
                case "reject": {
                    let status = "rejected";
                    await updateChallengeStatus(name, game_id, status);
                    showAlert(
                        <span>Successfully changed status to rejected for <strong>{name}</strong>!</span>, "success");
                    updateNonograms("rejected");
                    break;
                }
                case "reuse": {
                    let status = "available";
                    await updateChallengeStatus(name, game_id, status);
                    showAlert(
                        <span>Successfully changed status to available for <strong>{name}</strong>!</span>, "success");
                    updateNonograms("available");
                    break;
                }
                case "delete": {
                    await deleteChallenge(name, game_id);
                    showAlert(<span>Successfully deleted <strong>{name}</strong>!</span>, "success");
                    deleteNonogramLive();
                    break;
                }
            }
        }
        changeChallengePost();
        setChangeNonogram(false); // Reset the trigger
    }, [changeNonogram]);


    // Function to handle rescheduling a nonogram
    const rescheduleNonogram = (nonogram) => {
        // Use the nonogram's date if available, otherwise default to today's date
        const initialDate = nonogram.date || new Date().toISOString().slice(0, 10); // get the first ten digits of the string
        setRescheduleDate(initialDate);  // set the state of the reschedule date input
        setCurrentNonogram(nonogram); // Set the current nonogram to be rescheduled
        setOpenRescheduleDialog(true); // Open the reschedule dialog
    };

    // Function to handle selecting a nonogram (currently not implemented)
    const selectNonogram = async (nonogram) => {
        // things I want to do when I select the nonogram
        // open a dialog box that shows me the image I uploaded
        setCurrentNonogram(nonogram)
        await setopenSelectDialog(true);
        // allow me to edit the nonogram like the upload nonogram dialog box

        // confirm my edit

        // update the nonogram
        // showAlert(<span>Selecting is <strong>not yet</strong> implemented!</span>, "error");
    };

    // Function to reuse a nonogram (change status to 'available')
    const reuseNonogram = async (nonogram) => {
        await setCurrentNonogram(nonogram);
        await setMethod("reuse");
        await setChangeNonogram(true); // Trigger the change
    }

    // Function to reject a nonogram (change status to 'rejected')
    const rejectNonogram = async (nonogram) => {
        await setCurrentNonogram(nonogram);
        await setMethod("reject");
        await setChangeNonogram(true); // Trigger the change
    }

    // Function to handle confirmation of deletion
    const handleDeleteConfirmation = async () => {
        // Close the delete confirmation dialog
        setOpenDeleteDialog(false);
        // Proceed with deletion
        await setMethod("delete");
        await setChangeNonogram(true); // Trigger the change
    };

    // Function to initiate the deletion process (opens confirmation dialog)
    const deleteNonogram = (nonogram) => {
        setCurrentNonogram(nonogram);
        setOpenDeleteDialog(true); // Open the delete confirmation dialog
    };

    // Function to handle tab changes (Scheduled, Available, Used, Rejected)
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    // Function to render action buttons based on the active tab
    const renderButtons = (nonogram) => {
        switch (activeTab) {
            case 'scheduled':
                return (
                    <>
                        <Tooltip title="Select this nonogram for modification.">
                            <Button className="nonogram-select" color="primary"
                                    onClick={() => selectNonogram(nonogram)}>Select</Button>
                        </Tooltip>
                        <Tooltip
                            title="Change the scheduled date. Ensure to manually schedule another nonogram to maintain game continuity.">
                            <Button variant="contained" onClick={() => rescheduleNonogram(nonogram)} color="success">Change
                                Date</Button>
                        </Tooltip>
                        <Tooltip
                            title="Reject this nonogram, moving it to the rejection bucket. Ensure to manually schedule another nonogram to maintain game continuity.">
                            <Button variant="contained" color="error"
                                    onClick={() => rejectNonogram(nonogram)}>Reject</Button>
                        </Tooltip>
                    </>
                );
            case 'available':
                return (
                    <>
                        <Tooltip title="Select this nonogram for modification.">
                            <Button className="nonogram-select" color="primary"
                                    onClick={() => selectNonogram(nonogram)}> Select </Button>
                        </Tooltip>
                        <Tooltip title="Schedule this nonogram for use.">
                            <Button className="nonogram-schedule" onClick={() => rescheduleNonogram(nonogram)}
                                    color="success">Schedule</Button>
                        </Tooltip>
                        <Tooltip title="Reject this nonogram, moving it to the rejection bucket.">
                            <Button  className="nonogram-reject" variant="contained" color="error"
                                    onClick={() => rejectNonogram(nonogram)}>Reject</Button>
                        </Tooltip>
                    </>
                );
            case 'used':
                return (
                    <>
                        <Tooltip title="Select this nonogram for modification.">
                            <Button className="nonogram-select" color="primary"
                                    onClick={() => selectNonogram(nonogram)}>Select</Button>
                        </Tooltip>
                        <Tooltip title="Change the date, and schedule this nonogram again.">
                            <Button variant="contained" onClick={() => rescheduleNonogram(nonogram)}
                                    color="success">Reschedule</Button>
                        </Tooltip>
                        <Tooltip title="Return this nonogram to the available bucket.">
                            <Button variant="contained" onClick={() => reuseNonogram(nonogram)}
                                    color="secondary">Reuse</Button>
                        </Tooltip>
                    </>
                );
            case 'rejected':
                return (
                    <>
                        <Tooltip title="Select this nonogram for modification.">
                            <Button variant="contained" color="primary" className="nonogram-select"
                                    onClick={() => selectNonogram(nonogram)}>Select</Button>
                        </Tooltip>
                        <Tooltip title="Return this nonogram to the available bucket.">
                            <Button variant="contained" onClick={() => reuseNonogram(nonogram)}
                                    color="secondary">Reuse</Button>
                        </Tooltip>
                        <Tooltip title="Delete this nonogram permanently from the database.">
                            <Button variant="contained" className="nonogram-reject"
                                    onClick={() => deleteNonogram(nonogram)}>Delete</Button>
                        </Tooltip>
                    </>
                );
            default:
                return null;
        }
    };

    // Function to render the list of nonograms based on the active tab
    const renderNonogramList = (showDate = false) => (
        <div>
            {(nonograms[activeTab] || []).map((nonogram, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <canvas ref={canvas => drawNonogram(canvas, nonogram.data.split(','))}
                            style={{ marginRight: '10px', width: '100px', height: '100px' }}></canvas>
                    <div>
                        <div>Name: {nonogram.name}</div>
                        <div>Size: {Math.sqrt(nonogram.data.split(',').length)}x{Math.sqrt(nonogram.data.split(',').length)}</div>
                        {showDate && <div>Date: {nonogram.date || 'null'}</div>}
                        {renderButtons(nonogram)}
                    </div>
                </div>
            ))}
        </div>
    );

    // Render the component UI
    return (
        <div className="existing-nonograms-container">
            {/* Buttons to switch between tabs */}
            <div className="nonogram-status">
                <Button variant="outlined" onClick={() => handleTabChange('scheduled')}
                        color={activeTab === 'scheduled' ? 'primary' : 'inherit'}>Scheduled</Button>
                <Button variant="outlined" onClick={() => handleTabChange('available')}
                        color={activeTab === 'available' ? 'primary' : 'inherit'}>Available</Button>
                <Button variant="outlined" onClick={() => handleTabChange('used')}
                        color={activeTab === 'used' ? 'primary' : 'inherit'}>Used</Button>
                <Button variant="outlined" onClick={() => handleTabChange('rejected')}
                        color={activeTab === 'rejected' ? 'primary' : 'inherit'}>Rejected</Button>
            </div>
            {/* Display the list of nonograms */}
            <div className="nonogram-list">
                {renderNonogramList(activeTab === 'scheduled' || activeTab === 'used')}
            </div>

            <Dialog open={openSelectDialog} onClose={() => setopenSelectDialog(false)}>
                <DialogTitle className="editNonogramTitle">Edit Nonogram</DialogTitle>
                <DialogContent>
                    { currentNonogram &&
                        <NonogramGenerator game_id={game_id}
                                            initialNonogramData={currentNonogram.data}
                                            initialChallengeName={currentNonogram.name}
                                            onUpdate={() => {
                                                setopenSelectDialog(false);
                                                fetchNonograms(); // Refresh the nonogram list
                                            }}
                            />
                        }
                </DialogContent>

                <DialogActions>

                    <Button onClick={() => setopenSelectDialog(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Dialog for rescheduling a nonogram */}
            <Dialog open={openRescheduleDialog} onClose={() => setOpenRescheduleDialog(false)}>
                <DialogTitle>Reschedule Nonogram</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="date"
                        label="Date"
                        type="date"
                        fullWidth
                        variant="standard"
                        value={rescheduleDate}
                        inputProps={{
                            min: today,  // Set minimum date to today
                        }}
                        onChange={e => setRescheduleDate(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRescheduleDialog(false)}>Cancel</Button>
                    <Button onClick={async () => {
                        setOpenRescheduleDialog(false);
                        await setMethod("changedate");
                        await setChangeNonogram(true); // Trigger the change
                    }}>Implement</Button>
                </DialogActions>
            </Dialog>
            {/* Dialog for confirming deletion of a nonogram */}
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this nonogram? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                    <Button onClick={handleDeleteConfirmation} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

};

export default ExistingNonogramViewer; // Export the component
