import React, {useRef, useState} from "react";
import "../../Page/default_page_style.css"
import {CloudUpload} from "lucide-react";
import {Button} from "@mui/material";

// CrosswordViewer component to handle file upload and processing of crossword puzzle data
export const CrosswordGenerator = () => {
    // State to store the content of the uploaded file
    const [fileContent, setFileContent] = useState('');

    // state to store the across clues
    const[acrossClues, setAcrossClues] = useState([]);
    const[downClues, setDownClues] = useState([]);

    // state to store the across crosswords
    const[across, setAcross] = useState([]);
    const[down, setDown] = useState([]);
    const fileInputRef = useRef(null);

    // Function to handle file upload and read its content
    const handleFileUpload = (event) => {
        const file = event.target.files[0]; // Access the uploaded file
        if (file) {
            const reader = new FileReader(); // Create a FileReader to read the file
            reader.onload = (e) => {
                setFileContent(e.target.result); // Set the file content to state
                // Process the file content further
                processFileContent(e.target.result);
            };
            reader.readAsText(file); // Read the file as text
        }
    };

    // Function to process the file content (crossword puzzle data)
    const processFileContent = (content) => {
        let across_clues = []; // Array to store across clues
        let down_clues = [];   // Array to store down clues

        // Split the content into individual lines
        const separateLines = content.split(/\r?\n|\r|\n/g);
        const width = separateLines[0].length; // Get the width of the puzzle
        let full_puzzle = '';           // String to store the full puzzle
        let height = 0;

        // Combine puzzle rows until an empty line is encountered, making the full puzzle
        while (height < separateLines.length && separateLines[height] !== '') {
            full_puzzle = full_puzzle.concat(separateLines[height]);
            height = height + 1;
        }

        let cursor = height + 2; // Skip past the grid and clues metadata
        // Loop through across clues
        while (cursor < separateLines.length && separateLines[cursor].trim() !== "") {
            let data = separateLines[cursor].split(" ");
            if (data.length >= 3) { //check if the number of characters is more than three to avoid adding 'across' to the array
                across_clues.push([parseInt(data[0]), data.slice(1).join(' ')]); // Parse and store across clues with their numbers
            }
            cursor++; // go to the next line
        }

        cursor += 2; // Skip to the down clues
        let across = [];
        // Loop through down clues
        while (cursor < separateLines.length && separateLines[cursor].trim() !== "") {
            let data = separateLines[cursor].split(" ");
            if (data.length >= 3) {
                down_clues.push([parseInt(data[0]), data.slice(1).join(' ')]); // Parse and store down clues
            }
            cursor++;
        }

        // Helper function to replace specific characters in a string
        function replaceCharacter(str, targetChar, replacementChar) {
            return str.split(targetChar).join(replacementChar);
        }

        // Helper function to remove empty strings from an array
        function removeEmptyStrings(arr) {
            return arr.filter(function(str) {
                return str !== '';
            });
        }

        // Helper function to combine all elements after the first in an array
        function combineAfterFirst(arr) {
            return arr.slice(1).join(' ');
        }

        // Helper function to process a string (remove spaces, quotes, etc.)
        function processString(str) {
            return str
                .replace(/[ '\-,""]/g, '') // Remove spaces, single/double quotes, dashes, commas
                .toUpperCase();            // Convert to uppercase
        }

        cursor += 2; // Move cursor to across name array
         let across_name_array = replaceCharacter(separateLines[cursor] || '', '\uFFFD', ' ').split(/[:,.]/);
        // Process across clues and create clue objects
        for (let i = 1; i < across_name_array.length - 1; i++) {
            let data = removeEmptyStrings(across_name_array[i].split(" "));
            if (data.length > 0) {
                let data_num = parseInt(data[0]);
                let data_name = combineAfterFirst(data);
                let data_id = processString(data_name);

                // Match clues with parsed data and create across clue objects
                across_clues.forEach(clue => {
                    if (clue[0] === data_num) {
                        let clueObject = {
                            number: clue[0],
                            id: data_id,
                            name: data_name,
                            clue: clue[1]
                        };
                        across.push(clueObject); // Add clue to across array
                    }
                });
            }
        }

        cursor += 2; // Move cursor to down name array
        let down = [];
        let down_name_array = replaceCharacter(separateLines[cursor] || '', '\uFFFD', ' ').split(/[:,.]/);
        // Process down clues and create clue objects
        for (let i = 1; i < down_name_array.length - 1; i++) {
            let data = removeEmptyStrings(down_name_array[i].split(" "));
            if (data.length > 0) {
                let data_num = parseInt(data[0]);
                let data_name = combineAfterFirst(data);
                let data_id = processString(data_name);

                // Match clues with parsed data and create down clue objects
                down_clues.forEach(clue => {
                    if (clue[0] === data_num) {
                        let clueObject = {
                            number: clue[0],
                            id: data_id,
                            name: data_name,
                            clue: clue[1]
                        };
                        down.push(clueObject); // Add clue to down array
                    }
                });
            }
        }

        // Initialize empty board for the puzzle
        let number_board = Array.from({ length: height }, () => Array(width).fill('.'));

        // Initialize an object to store clues' coordinates
        let clues_coordinates = { across: [], down: [] };

        // Function to place a number on the board and store coordinates
        const placeNumber = (board, word, direction) => {
            let { number, id } = word;
            let pos;

            if (direction === 'across') {
                pos = full_puzzle.indexOf(id); // Find position of the word in the puzzle for across clues
            } else if (direction === 'down') {
                // Search for down clues' position
                for (let i = 0; i < full_puzzle.length; i++) {
                    let match = true;
                    for (let j = 0; j < id.length; j++) {
                        if (full_puzzle[i + j * width] !== id[j]) {
                            match = false;
                            break;
                        }
                    }
                    if (match) {
                        pos = i;
                        break;
                    }
                }
            }

            if (pos === undefined || pos === -1) {
                console.error(`ID ${id} not found in the puzzle.`);
                return;
            }

            let row = Math.floor(pos / width); // Calculate row position
            let col = pos % width;             // Calculate column position

            // Convert coordinates to 1-based system
            let x = col + 1;
            let y = row + 1;

            // Store coordinates in the appropriate direction (across/down)
            if (direction === 'across') {
                clues_coordinates.across.push({ number, x, y });
            } else {
                clues_coordinates.down.push({ number, x, y });
            }

            // Place the number on the puzzle board
            board[row][col] = number;
        };

        // Place all across words on the board
        across.forEach(word => {
            placeNumber(number_board, word, 'across');
        });

        // Place all down words on the board
        down.forEach(word => {
            placeNumber(number_board, word, 'down');
        });

        // Debugging outputs for the console
        console.log(full_puzzle);
        console.log("Height of the puzzle:", height);
        console.log("Width of the puzzle:", width);
        console.log(across);
        console.log(across_clues)
        console.log(down_clues)
        console.log(down);
        console.log("X and Y show where on the board the word starts. X,Y (1,1) is on the top left (not bottom left).");
    };

    return (
        <div>
            {/*<h1>Crossword Generator</h1>*/}
            {/* Input to upload a .txt file */}
            <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                className="file-input"
                ref={fileInputRef}  // Reference to this element
                style={{display: "none"}} // Keep the input hidden
            />

            <Button className="Button" startIcon={<CloudUpload/>} variant="contained"
                    onClick={() => fileInputRef.current && fileInputRef.current.click()}  // Trigger click on the hidden file input
            >
                Upload files
            </Button>
        </div>
    );
};