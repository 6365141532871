// component_manager.js
import React, {useEffect, useState} from "react";
import {
    Checkbox,
    Menu,
    MenuItem,
    Paper,
    Select,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import {post, useAdministrationContext} from "./administration_context";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import LoadingScreen from "../Unique/Animation/loading_screen";
import {useAlertContext} from '../Page/alert_context';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Fix this shit, some dickhead (Ilir) hardcoded the fucking valued, absolute dickhead, bro can't code for shit

export const components = [
    {name: "Label Table", status: "Discontinued", page: "All", sub_page: "Main"},
    {name: "Challenge Viewer", status: "Live", page: "All", sub_page: "Main"},
    {name: "Challenge Editor", status: "Discontinued", page: "All", sub_page: "Main"},
    {name: "Trivia Generator", status: "Live", page: "Trivia", sub_page: "Main"},
    {name: "Nonogram Generator", status: "Live", page: "Nonogram", sub_page: "Main"},
    {name: "Component Manager", status: "Live", page: "Administration", sub_page: "Main"},
    {name: "Page Manager", status: "Live", page: "Administration", sub_page: "Main"},
    {name: "User Manager", status: "Live", page: "Administration", sub_page: "Main"},
    {name: "Graph", status: "Live", page: "All", sub_page: "Statistics"},
    {name: "Numbers", status: "Live", page: "All", sub_page: "Statistics"},
    {name: "Feedback", status: "Live", page: "All", sub_page: "Statistics"},
    {name: "Nonogram Viewer", status: "Live", page: "Nonogram", sub_page: "Main"},
    {name: "File Uploader", status: "Live", page: "All", sub_page: "Main"},
    {name: "Structure Preview", status: "Live", page: "Combinations", sub_page: "Main"},
    {name: "Tile-set Preview", status: "Live", page: "Combinations", sub_page: "Main"},
    {name: "Existing Nonograms", status: "Live", page: "Nonogram", sub_page: "Main"},
    {name: "In-depth Statistics", status: "Live", page: "All", sub_page: "Statistics"},
    {name: "Users", status: "Live", page: "All", sub_page: "Statistics"},
    {name: "Completion Pie Chart", status: "Live", page: "All", sub_page: "Statistics"},
    {name: "Total Time Graph", status: "Live", page: "All", sub_page: "Statistics"}
];

const structureLiveComponents = (liveComponents) => {
    let result = [];
    if (Array.isArray(liveComponents)) {
        liveComponents.forEach(component => {
            if (component[1] === "") result.push({
                name: component[0],
                page: component[2],
                sub_page: "Main",
                sort_order: component[3]
            });
            else result.push({
                name: component[0],
                page: component[1],
                sub_page: component[2],
                sort_order: component[3]
            });
        });
    }
    return result;
};

const structurePages = (pages) => {
    let result = [];
    if (Array.isArray(pages)) {
        pages.forEach(page => {
            if (page[0] === "") result.push({page: page[1], sub_page: "Main"});
            else result.push({page: page[0], sub_page: page[1]});
        });
    }
    return result;
};

const filterComponents = (page, sub_page, live_components) => {
    let converted_components = [];
    let converted_liveComponents = [];
    let filtered_components = [];

    components.forEach(component => {
        if ((component["page"] === "All" || component["page"] === page) && (component["sub_page"] === "All" || component["sub_page"] === sub_page)) converted_components.push(component);
    });
    live_components.forEach(component => {
        if ((component["page"] === page) && (component["sub_page"] === sub_page)) converted_liveComponents.push(component);
    });

    converted_components.forEach(component => {
        const liveComponent = converted_liveComponents.find(liveComponent => liveComponent.name === component.name);
        const isLive = !!liveComponent;
        filtered_components.push({
            ...component,
            state: isLive,
            sort_order: liveComponent ? liveComponent.sort_order : 0
        });
    });

    return filtered_components.sort((a, b) => {
        if (a.state && !b.state) return -1;
        if (!a.state && b.state) return 1;
        return 0;
    });
};

function getPostData(component, selectedPage, selectedSubPage) {
    let parent_id;
    let page_id;

    if (selectedSubPage === "Main") {
        parent_id = "";
        page_id = selectedPage;
    } else {
        parent_id = selectedPage;
        page_id = selectedSubPage;
    }
    return {
        method: component["state"] ? 'removePageComponent' : 'addPageComponent',
        name: component["name"],
        parent_id: parent_id,
        page_id: page_id
    };
}

function getSortOrderPostData(component, newSortOrder, selectedPage, selectedSubPage) {
    let parent_id;
    let page_id;

    if (selectedSubPage === "Main") {
        parent_id = "";
        page_id = selectedPage;
    } else {
        parent_id = selectedPage;
        page_id = selectedSubPage;
    }
    return {
        method: 'changeSortOrder',
        name: component["name"],
        order: newSortOrder,
        parent_id: parent_id,
        page_id: page_id
    };
}

const ComponentManager = () => {
    const {user} = useAdministrationContext();
    const [pages, setPages] = useState([]);
    const [liveComponents, setLiveComponents] = useState([]);
    const [selectedPage, setSelectedPage] = useState('');
    const [selectedSubPage, setSelectedSubPage] = useState('');
    const [filteredComponents, setFilteredComponents] = useState([]);
    const [changedComponents, setChangedComponents] = useState([]);
    const [showApplyButton, setShowApplyButton] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const {showAlert} = useAlertContext();

    useEffect(() => {
        const getPages = async () => {
            const pagesData = await post({method: 'getPagesSimple', user_id: user});
            setPages(structurePages(pagesData));
        };
        getPages();

        const getLiveComponents = async () => {
            const liveComponentsData = await post({method: 'getComponentsWithOrder', user_id: user});
            setLiveComponents(structureLiveComponents(liveComponentsData));
        };
        getLiveComponents();
    }, [user]);

    useEffect(() => {
        if (selectedPage && selectedSubPage) {
            setFilteredComponents(filterComponents(selectedPage, selectedSubPage, liveComponents));
        }
    }, [selectedPage, selectedSubPage, liveComponents]);

    const handlePageChange = (event) => {
        setSelectedPage(event.target.value);
        const subPages = pages.filter(p => p.page === event.target.value).map(p => p.sub_page);
        if (subPages.length > 0) setSelectedSubPage(subPages[0]);
    };

    const handleSubPageChange = (event) => {
        setSelectedSubPage(event.target.value);
    };

    const handleCheckboxChange = (component, index) => {
        const postData = getPostData(component, selectedPage, selectedSubPage);
        post(postData);
        const isCurrentlyLive = component.state;

        if (isCurrentlyLive) {
            setLiveComponents(liveComponents.filter(liveComponent => liveComponent.name !== component.name));
        } else {
            setLiveComponents([...liveComponents, {
                name: component.name,
                page: selectedPage,
                sub_page: selectedSubPage,
                sort_order: component.sort_order,
                state: true
            }]);
        }

        setShowApplyButton(true); // Show the apply button when a checkbox is changed
    };

    const handleSortOrderChange = (component, newSortOrder) => {
        const updatedComponent = {...component, sort_order: newSortOrder};
        const postData = getSortOrderPostData(component, newSortOrder, selectedPage, selectedSubPage);
        post(postData);
        setLiveComponents(liveComponents.map(liveComponent =>
            liveComponent.name === component.name ? {...liveComponent, sort_order: newSortOrder} : liveComponent
        ));
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(filteredComponents);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // Update sort order of checked items only
        const checkedItems = items.filter(item => item.state);
        checkedItems.forEach((item, index) => {
            item.sort_order = index;
            // Mark the item as changed
            setChangedComponents(prev => [...prev.filter(c => c.name !== item.name), item]);
        });

        setFilteredComponents(items);
        setShowApplyButton(true); // Show the apply button when items are dragged
    };

    const applyChanges = async () => {
        setLoading(true); // Show loading screen

        await Promise.all(changedComponents.map(async (component) => {
            const postData = getSortOrderPostData(component, component.sort_order, selectedPage, selectedSubPage);
            await post(postData);
        }));

        // Refresh the live components
        const getLiveComponents = async () => {
            const liveComponentsData = await post({method: 'getComponentsWithOrder', user_id: user});
            const structuredLiveComponents = structureLiveComponents(liveComponentsData);
            setLiveComponents(structuredLiveComponents);

            // Update filteredComponents after refreshing liveComponents
            setFilteredComponents(filterComponents(selectedPage, selectedSubPage, structuredLiveComponents));
        };
        await getLiveComponents();

        setChangedComponents([]);
        setShowApplyButton(false); // Hide the apply button after changes are applied
        setLoading(false); // Hide loading screen

        window.location.reload(); // Refresh the page
    };

    const renderComponentRow = (component, index, provided) => (
        <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    className="custom-checkbox"
                    checked={component.state}
                    onChange={() => handleCheckboxChange(component, index)}
                />
            </TableCell>
            <TableCell>{component.name}</TableCell>
            <TableCell>{component.status}</TableCell>
            <TableCell style={{cursor: 'grab'}}>
                &#x2630; {/* Unicode character for a draggable handle */}
            </TableCell>
        </TableRow>
    );

    return (
        <div>
            {loading && <LoadingScreen/>} {/* Display loading screen when loading */}
            <div>
                <div className={"button-stretch"}>
                    <div style={{marginBottom:'15px'}}>
                        <Typography variant="h6">Pick your page:</Typography>
                        <FormControl>

                            <InputLabel className="input-label"> Page </InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={selectedPage}
                                onChange={handlePageChange}
                                sx={{minWidth: '100px'}}
                                label="Page"
                            >
                                {pages.map((p, idx) => (
                                    <MenuItem key={idx} value={p.page}>
                                        {p.page}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>

                            <InputLabel className="input-label" >Sub Page</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={selectedSubPage}
                                onChange={handleSubPageChange}
                                sx={{minWidth: '120px'}}
                                label="Sub-Page"
                            >
                                {pages.filter(p => p.page === selectedPage).map((p, idx) =>
                                    <MenuItem key={idx} value={p.sub_page}>
                                        {p.sub_page}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    {showApplyButton && (
                        <Button className={"Button"} onClick={applyChanges}>
                            Apply Changes
                        </Button>
                    )}
                </div>
                <div>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        {filteredComponents.length > 0 && (
                            <Droppable droppableId="droppable-components">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <TableContainer component={Paper} className="custom-table">
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan={4}>
                                                            <Typography variant="h6">Active Components</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    {filteredComponents
                                                        .filter(component => component.state)
                                                        .map((component, index) => (
                                                            <Draggable key={component.name}
                                                                       draggableId={`draggable-${component.name}`}
                                                                       index={index}>
                                                                {(provided) => renderComponentRow(component, index, provided)}
                                                            </Draggable>
                                                        ))}
                                                    {provided.placeholder}
                                                    <TableRow>
                                                        <TableCell colSpan={4}>
                                                            <Typography variant="h6">
                                                                Inactive Components
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    {filteredComponents
                                                        .filter(component => !component.state)
                                                        .map((component, index) => (
                                                            <Draggable key={component.name}
                                                                       draggableId={`draggable-${component.name}`}
                                                                       index={index + filteredComponents.filter(component => component.state).length}>
                                                                {(provided) => renderComponentRow(component, index + filteredComponents.filter(component => component.state).length, provided)}
                                                            </Draggable>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                )}
                            </Droppable>
                        )}
                    </DragDropContext>
                </div>
            </div>
        </div>
    );
};

export default ComponentManager;
