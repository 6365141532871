import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiSelect: {
            defaultProps: {
                variant: "outlined",
            },

            styleOverrides: {
                root: {
                    backgroundColor: 'var(--main)',
                    border: '1px transparent',
                    color: 'var(--text-light)',
                    height: '2rem',
                    width: 'auto',
                    padding: '0 0.2rem 0 0.8rem',
                    marginRight: '1rem',
                    alignItems: 'center',
                    textAlign: 'center',
                    textDecoration: 'none',
                    display: 'flex',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    transition: 'background-color 0.3s ease',
                    fontWeight: 700,
                    whiteSpace: 'nowrap', // Corrected property
                    textTransform: 'uppercase',

                    '&:hover': {
                        backgroundColor: 'var(--main-hover)', // hover effect
                       // borderRadius: '8px',
                    },

                },
            },
        },

        MuiButton: {
            defaultProps:{
                variant:"contained",
            },
            styleOverrides: {
                root: {

                    backgroundColor: 'var(--main)',
                    border: '1px transparent',
                    color: 'var(--text-light)',
                    padding: '1rem',
                    height: '2.5rem',
                    alignItems: 'center',
                    textAlign: 'center',
                    textDecoration: 'none',
                    display: 'flex',
                    margin: '4px 0',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    transition: 'background-color 0.3s ease',
                    fontWeight: 700,
                    whiteSpace: 'nowrap', // Corrected property
                    textTransform: 'uppercase',

                    // Uncomment the line below if you want to include text shadow
                    // textShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
                    '&:hover': {
                        backgroundColor: 'var(--main-hover)', // Optional hover effect
                    },
                },
            },
        },
    },
});

export default theme;
