import React, {useEffect, useState} from "react";
import SimpleGraph from "./simple_graph.js";
import "./statistics.css";
import {useSidebarContext} from "../Sidebar/sidebar_context";
import DownloadCSVButton from "../Unique/Misc/download_csv_button";
import {useStatisticsContext} from "./statistics_context";
import Tooltip from '@mui/material/Tooltip';




const FourGraph = ({game_id}) => {
    // const [statistics, setStatistics] = useState({});
    const [retention, setRetention] = useState({});
    const [loading, setLoading] = useState(false);
    const {dates} = useSidebarContext();
    const {generalStatistics, generalStatsLoading, setGeneralStatsLoading} = useStatisticsContext();
    // const [exportData, setExportData] = useState([]);

    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    let yesterdayFormatted = yesterday.toISOString().split('T')[0];

    // Initialize retention date to 11 days ago as the default
    const getDefaultDate = () => {
        const date = new Date();
        date.setDate(date.getDate() - 11);
        return date.toISOString().split('T')[0];
    };

    // console.log("game_id four graph: "+game_id);

    const [retentionDate, setRetentionDate] = useState(getDefaultDate());

    useEffect(() => {
        if (!game_id) {
            // console.log("No game_id provided, aborting fetch.");
            return;
        }
        // console.log("Retrieving data");
        const controller = new AbortController();
        const {signal} = controller;

        const fetchRetentionRateData = async () => {
            setLoading(true);
            try {
                const url = `${process.env.REACT_APP_API_URL_STATISTICS}?method=getRetentionRate&date=${retentionDate}&gameid=${game_id}`;
                const response = await fetch(url, {
                    signal,
                    credentials: 'include',
                });
                if (!response.ok) throw new Error('Network response was not ok.');

                const data = await response.json();
                if (!signal.aborted) {
                    setRetention(data);
                    // console.log("1st RETENTION RATE:");
                    // console.log(data);
                }
            } catch (error) {
                if (!signal.aborted) {
                    console.error('Failed to fetch data:', error.message);
                }
            } finally {
                if (!signal.aborted) {
                    setLoading(false);
                }
            }
        };

        fetchRetentionRateData();

        // Cleanup function
        return () => {
            // console.log("Aborting fetch for game_id:", game_id);
            controller.abort();
        };
    }, []);


    const handleDateChange = (event) => {
        setLoading(true);
        if (!game_id) {
            // console.log("No game_id provided, aborting fetch.");
            return;
        }
        const newDate = event.target.value;
        setRetentionDate(newDate); // Update state for future re-renders

        const controller = new AbortController();
        const {signal} = controller;

        const fetchRetentionRateData = async () => {
            try {
                const url = `${process.env.REACT_APP_API_URL_STATISTICS}?method=getRetentionRate&date=${newDate}&gameid=${game_id}`;
                // console.log('Re-fetch retention:', url);
                const response = await fetch(url, {
                    signal,
                    credentials: 'include',
                });
                if (!response.ok) throw new Error('Network response was not ok.');

                const data = await response.json();
                if (!signal.aborted) {
                    setRetention(data);
                }
            } catch (error) {
                console.error("Error fetching retention rate:", error);
            } finally {
                if (!signal.aborted) {
                    setLoading(false);
                }
            }
        };

        fetchRetentionRateData();

        // Cleanup function to abort previous fetches if the input changes again
        return () => {
            // console.log("Aborting fetch for date change.");
            controller.abort();
        };
    };


    useEffect(() => {
        // console.log("retention: ");
        // console.log(retention);
    }, [retention]);

    if (generalStatsLoading || loading) {
        return <div>Loading...</div>; // Show loading until all data is ready
    }

    const formattedExportData = {
        date: generalStatistics ? generalStatistics["date"] || 'Loading date...' : 'Loading date...',
        "Total Gameplays": generalStatistics ? generalStatistics["Total Launches"] || 'Loading total gameplays...' : 'Loading total gameplays...',
        "Total Users": generalStatistics ? generalStatistics["Total Unique Users who played the daily challenge"] || 'Loading total users...' : 'Loading total users...',
        "Total Advertisements": generalStatistics ? generalStatistics["Total Ads"] || 'Loading total advertisements...' : 'Loading total advertisements...',
        "Retention Rate": retention && retention["day1_retention_percentage"] && retention["day1_retention_percentage"][0] !== undefined
            ? [100, retention["day1_retention_percentage"][0], retention["day7_retention_percentage"][0], retention["day10_retention_percentage"][0]]
            : ['Loading retention rates...']
    };


    return (
        <div>
            <div className="four-graph-container">
                <>
                    <div className="small-graph-container">
                        <h3>Total Gameplays</h3>
                        {generalStatistics && generalStatistics["Total Gameplays"] ? (
                            <SimpleGraph key={Date.now()} x={generalStatistics["date"]}
                                         y={generalStatistics["Total Gameplays"]}
                                         type={'scatter'}/>
                        ) : (
                            <p>Loading total gameplays...</p>
                        )}
                    </div>
                    <div className="small-graph-container">
                        <Tooltip title="Total Unique Users who entered the game."><h3>Total Users</h3></Tooltip>
                        {generalStatistics && generalStatistics["Total Unique Users who entered the game"] ? (
                            <SimpleGraph key={Date.now()} x={generalStatistics["date"]}
                                         y={generalStatistics["Total Unique Users who entered the game"]}
                                         type={'scatter'}/>
                        ) : (
                            <p>Loading total users...</p>
                        )}
                    </div>
                    <div className="small-graph-container">
                        <h3>Total Advertisements</h3>
                        {generalStatistics && generalStatistics["Total Ads"] ? (
                            <SimpleGraph key={Date.now()} x={generalStatistics["date"]}
                                         y={generalStatistics["Total Ads"]}
                                         type={'scatter'}/>
                        ) : (
                            <p>Loading total advertisements...</p>
                        )}
                    </div>
                    <div className="small-graph-container">
                        <h3>10 Day Retention</h3>
                        {retention && retention["day1_retention_percentage"] && retention["day1_retention_percentage"][0] !== undefined ? (
                            <SimpleGraph key={Date.now()} x={['Day 0', 'Day 1', 'Day 7', 'Day 10']}
                                         y={[
                                             100,
                                             retention["day1_retention_percentage"][0],
                                             retention["day7_retention_percentage"][0],
                                             retention["day10_retention_percentage"][0]
                                         ]}
                                         type={'bar'}/>
                        ) : (
                            <p>Loading retention rates...</p>
                        )}
                    </div>
                </>
            </div>
            <div className="button-stretch">
                <div className="Retention-date">
                    <label className="date-label">Retention date:</label>
                    <input className="date"  type="date" value={retentionDate} onChange={handleDateChange} max={getDefaultDate()}/>
                </div>
                <DownloadCSVButton data={formattedExportData} fileName={"Users Stats for Game ID " + game_id}/>
            </div>

        </div>
    );
};

export default FourGraph;
