import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Paper } from '@mui/material';

import { post, useAdministrationContext } from "./administration_context";
import {useAlertContext} from "../Page/alert_context";

const PageManager = () => {
    const { user } = useAdministrationContext();
    const [pages, setPages] = useState([]);
    const [userPages, setUserPages] = useState([]);
    const [username, setUsername] = useState("");

    const { showAlert } = useAlertContext();

    useEffect(() => {
        const fetchData = async () => {
            const allPages = await post({ method: 'getPages' });
            const userSpecificPages = await post({ method: 'getPagesOfUser', id: user });
            const usernameFromID = await post({ method: 'getUsernameFromID', user_id: user });

            setPages(allPages);
            setUserPages(userSpecificPages);
            setUsername(usernameFromID[0][0]);
        };

        fetchData();
    }, [user]);

    const isPageChecked = (page) => {
        return userPages.some(userPage => JSON.stringify(userPage) === JSON.stringify(page));
    };

    const handleCheckboxChange = async (page) => {
        const method = isPageChecked(page) ? 'removePage' : 'addPage';
        const data = {
            method,
            parent_id: page[0], // Assuming page structure includes parent_id
            page_id: page[1], // Assuming page structure includes page_id
            version_id: page[2], // Assuming page structure includes version_id
            user_id: user,
            game_id: page[3] // Assuming page structure includes game_id
        }
        // console.log(data)
        // console.log(page)
        await post(data);
        if(method === 'addPage'){
            // page[1]
            showAlert(<span>Added access to page <strong>{page[1]}</strong> for user <strong>{username}</strong>! (ID: {user})</span>, "success");
        }
        else{
            showAlert(<span>Removed access to page <strong>{page[1]}</strong> for user <strong>{username}</strong>! (ID: {user})</span>, "warning");
        }

        const updatedUserPages = method === 'addPage'
            ? [...userPages, page]
            : userPages.filter(userPage => JSON.stringify(userPage) !== JSON.stringify(page));

        setUserPages(updatedUserPages);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell>Game</TableCell>
                        <TableCell>Variant</TableCell>
                        <TableCell>Game ID</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pages.map((page, index) => (
                        <TableRow key={index}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    className="custom-checkbox"
                                    checked={isPageChecked(page)}
                                    onChange={() => handleCheckboxChange(page)}
                                />
                            </TableCell>
                            <TableCell>{page[0]} {page[1]}</TableCell>
                            <TableCell>{page[2]}</TableCell>
                            <TableCell align={"center"}>{page[3]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default PageManager;