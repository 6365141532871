import React, {useRef} from 'react';
import "./sidebar.css";
import { Gamepad2} from 'lucide-react';
import {useSidebarContext} from "./sidebar_context";

// Function to determine the styling of a sidebar item based on its active state
const styleSidebarItem = (isActive) => {
    return "sidebar-item " + (isActive ? 'active-link' : 'link');
};


const LinkDrop = ({ Icon, base, handleMouseLeave, handleMouseEnter, isActive }) => {
    const containerRef = useRef(null);
    const {mode} = useSidebarContext();



    return (
        <div
            ref = {containerRef}
            style={{ position: 'relative' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className={styleSidebarItem(isActive)}>
                {Icon ? (
                    <div className="sidebar-image-container">
                        <img src={Icon} alt={`${base} icon`} className="sidebar-images" />
                    </div>
                ) : (
                    <Gamepad2 className = {`sidebar-icon ${mode}`}/>
                )}
            </div>
        </div>
    );
};

export default LinkDrop;
