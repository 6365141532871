// page_header.js

import React, {useEffect, useRef, useState} from "react";
import {useSidebarContext} from "../Sidebar/sidebar_context";
import Divider from '@mui/material/Divider';
import './page_header.css';
import Profile from "../../Pages/Settings/user_profile";
import {Menu, MenuItem, useMediaQuery} from '@mui/material';
import {ChevronDown} from 'lucide-react';
import logo from "../Assets/Denda_New3.png";
import logo_dark from "../Assets/Denda_black.png";



// Function to filter games based on the provided game name.
// Returns an array of game objects that match the given game name with only `game_id` and `version`.
const filterGames = (games, gameName) => {
    return (games || []).filter(game => game.name === gameName)
        .map(game => ({game_id: game.game_id, version: game.version}));
}

// Function to get the game ID based on game name and version.
// If an exact match isn't found, it falls back to the first match by name.
const getGameId = (games, gameName, gameVersion) => {
    let game = (games || []).find(game => game.name === gameName && game.version === gameVersion);
    if (!game) {
        game = (games || []).find(game => game.name === gameName);
    }
    return game ? game.game_id : null; // Returns the game ID if found, otherwise returns null.
}


// PageHeader component definition
const PageHeader = ({title, toggleSidebar}) => {
    const isMobile = useMediaQuery(`(max-width:768px)`);

    // Extracting necessary state values and functions from Sidebar context
    const {game_id, brand, setGame_id, games, mode, inverse, setInverse} = useSidebarContext();

    // Local state to store filtered games based on the current page title
    const [pageGames, setPageGames] = useState(filterGames(games, title));

    // State to manage whether the dropdown menu is visible
    const [showDropdown, setShowDropdown] = useState(false);

    // Ref to track the dropdown element (used to detect clicks outside the dropdown)
    const dropdownRef = useRef(null);

    // Extracting additional values from Sidebar context to manage sidebar state
    const {isSidebarOpen, toggleMobileSidebar} = useSidebarContext();

    // State to store the selected version of the game
    const [version, setVersion] = useState("");

    // State to control the visibility of the header when scrolling
    const [showHeader, setShowHeader] = useState(true);

    // Ref to keep track of the last scroll position
    const lastScrollY = useRef(0);

    // State to manage the dropdown button anchor element for positioning
    const [anchorEl, setAnchorEl] = useState(null);

    // Handle visibility of header when scrolling
    useEffect(() => {
        const controlHeaderVisibility = () => {
            // Hide header when scrolling down and show when scrolling up
            const shouldHideHeader = window.scrollY > lastScrollY.current && window.scrollY > 50;
            setShowHeader(!shouldHideHeader || isSidebarOpen);
            lastScrollY.current = window.scrollY; // Update last scroll position
        };

        // Add scroll event listener
        window.addEventListener('scroll', controlHeaderVisibility);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', controlHeaderVisibility);
        };
    }, [isSidebarOpen]); // Dependency ensures the effect runs whenever `isSidebarOpen` changes

    // Handle closing dropdown when clicking outside of the dropdown area
    useEffect(() => {
        const handleClickOutside = (event) => {
            // If the clicked element is not part of the dropdown, close the dropdown
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        // Add event listener for mouse clicks
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    // Update the selected version based on the current game_id when `pageGames` changes
    useEffect(() => {
        const game = pageGames.find(game => game.game_id === game_id);
        if (game) {
            setVersion(game.version);
        }
    }, [pageGames]); // Dependency ensures the effect runs whenever `pageGames` changes

    // Apply the theme class to `<body>` based on the selected mode (dark/light)
    useEffect(() => {
        document.body.classList.toggle('theme-dark', mode === 'dark');
        document.body.classList.toggle('theme-light', mode === 'light');
    }, [mode]); // Dependency ensures the effect runs whenever `mode` changes

    // Update `game_id` and `version` based on the page title or changes in `games`
    useEffect(() => {
        if (games) {
            // Get the game ID that matches the current title and brand
            const gameId = getGameId(games, title, brand);
            if (gameId !== null) {
                setGame_id(gameId);
            }
            // Update pageGames with the filtered games for the current title
            setPageGames(filterGames(games, title));
        }
        // Update version if it's already selected and game_id is valid
        if (version !== "" && game_id !== null) {
            let gameFromId = games.find(game => game.game_id === game_id);
            if (gameFromId !== null) {
                setVersion(gameFromId["version"]);
            }
        }
    }, [title]); // Dependency ensures the effect runs whenever `title` changes

    // Update the inverse color mode based on the current mode
    useEffect(() => {
        mode === "light" ? setInverse("dark") : setInverse("light");
    }, [mode, setInverse]);

    // Handle dropdown visibility when clicking the dropdown button
    const handleDropdown = (event) => {
        event.preventDefault();  // Prevent default browser behavior
        event.stopPropagation(); // Prevent click event from bubbling up to parent elements
        if (games && games.length > 1) {
            setShowDropdown(prevShowDropdown => !prevShowDropdown);
        }
    };

    // Handle click event to set the dropdown menu anchor
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Handle closing the dropdown menu
    const handleClose = (selectedVersion) => {
        setAnchorEl(null);
        // If a version is selected, update the version using `handleVersionChange`
        if (selectedVersion !== undefined) {
            handleVersionChange({target: {value: selectedVersion}});
        }
    };

    // Handle game selection from the dropdown menu by updating `game_id` and hiding the dropdown
    const handleGameSelect = (id) => {
        setGame_id(id);
        setShowDropdown(false);
        let gameFromId = games.find(game => game.game_id === id);
        setVersion(gameFromId["version"]);
    };

    // Handle version change when selecting a version from dropdown
    const handleVersionChange = (event) => {
        const selectedVersion = event.target.value;
        setVersion(selectedVersion);

        const selectedGame = pageGames.find(game => game.version === selectedVersion);
        if (selectedGame) {
            setGame_id(selectedGame.game_id);
            // Save globally selected version
            localStorage.setItem('selectedVersion', selectedVersion);
        }
    };



    useEffect(() => {
        if (games) {
            // Filter pageGames for the new title
            const updatedPageGames = filterGames(games, title);
            setPageGames(updatedPageGames);

            // Check if there's a saved version for this game title
            const savedVersion = localStorage.getItem(`version_for_${title}`);
            if (savedVersion && updatedPageGames.some(game => game.version === savedVersion)) {
                setVersion(savedVersion);
                // Also update game_id based on that saved version
                const savedGame = updatedPageGames.find(game => game.version === savedVersion);
                if (savedGame) {
                    setGame_id(savedGame.game_id);
                }
            } else if (updatedPageGames.length > 0) {
                // If no saved version, just default to the first version or whatever logic you currently have
                setVersion(updatedPageGames[0].version);
                setGame_id(updatedPageGames[0].game_id);
            }
        }
    }, [title, games, setGame_id, setPageGames]);


    // useEffect((event) => {
    //     if(pageGames.find(version)) {
    //         console.log(pageGames);
    //     }
    // }, [pageGames]);

    // Render the PageHeader component
    return (
        <div className={`header-container ${mode} ${showHeader ? '' : 'hide-header'}`}>
            {/* Logo display based on mode */}
            <div className="logo-container">
                {mode === 'dark' ? // Display the correct logo based on the current mode
                    (<img src={logo} alt="Logo" className={`d-md-block header-logo`}/>) :
                    (<img src={logo_dark} alt="Dark Logo" className={`d-md-block header-logo`}/>)
                }
            </div>

            <div className="title-with-dropdown">
                {/* Display brand name, fallback to "DPG" if brand is "Anonymous" */}
                <h1 className={`words ${mode}`}> {brand === "Anonymous" ? "DPG" : brand} </h1>

                {/* Vertical divider between brand and title */}
                <Divider className={`divider ${inverse}`} orientation="vertical" variant="middle"/>

                {/* Page title */}
                <h1 className={`words ${mode}`}>  {title}  </h1>

                {/* Conditionally render dropdown menu if it's visible */}
                {showDropdown && pageGames && pageGames.length > 0 && (
                    <div className="dropdown-menu-denda" ref={dropdownRef}>
                        {pageGames.map(game => (
                            <div key={game.game_id}
                                 onClick={() => handleGameSelect(game.game_id) && toggleMobileSidebar}>
                                {game.version}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="title-right">
                {/* Show the version dropdown button if there are multiple versions */}
                {pageGames && pageGames.length > 1 && (
                    <div>
                        <button className="version-select" onClick={handleClick}>
                            {version} <ChevronDown/> {/* Show the currently selected version */}
                        </button>

                        {/* Dropdown menu for selecting game versions */}
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => handleClose()}
                        >
                            {pageGames.map((game) => (
                                <MenuItem
                                    key={game.game_id}
                                    selected={game.version === version}
                                    onClick={() => handleClose(game.version)}
                                >
                                    {game.version}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                )}

               { isMobile ? ("") : (
                   // Profile section
                   <div className="profile">
                       <Profile/>
                   </div>
               )
            }
                {/* Mobile burger menu icon to toggle the sidebar */}
                <div className="burger-mobile d-block d-sm-block d-md-none" onClick={toggleMobileSidebar}>
                    <div className="burger-line"></div>
                    <div className="burger-line"></div>
                    <div className="burger-line"></div>
                </div>
            </div>
        </div>
    );
};

export default PageHeader;
