import Denda from "../Assets/Denda's_games.png";
import React, {useState} from "react";
import {useSidebarContext} from "../Sidebar/sidebar_context";
import "./Game-Page.css";
import {ChevronRight} from 'lucide-react';
import {Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider} from '@mui/material';
import {CalendarPlus, ChartNoAxesCombined} from 'lucide-react';
import {NavLink} from "react-router-dom";


function GamePage() {
    const {structuredPages, mode} = useSidebarContext(); // Extract structuredPages from context
    const [open, setOpen] = useState(false); // State for drawer open/close
    const [currentPageKey, setCurrentPageKey] = useState(null); // Store the current game key

    // Toggle drawer open/close
    const handleToggleDrawer = (gameName) => {
        setCurrentPageKey(gameName); // Set the selected page
        setOpen(true); // Open the drawer
    };

    // Close the drawer
    const handleCloseDrawer = () => {
        setOpen(false); // Close the drawer
    };

    // Render the drawer content dynamically based on subpages
    const DrawerList = () => {
        const subpages = structuredPages[currentPageKey]?.subpages || {}; // Get subpages for the current page

        if (Object.keys(subpages).length === 0) {
            return (
                <List>
                    <ListItem>
                        <ListItemText primary="No subpages available yet ;)"/>
                    </ListItem>
                </List>
            );
        }

        return (
            <List>
                {/* Static "Planning" link */}
                <ListItem disablePadding>
                        <ListItemButton className="navlink" component={NavLink} to={`/${currentPageKey}`}>
                            <ListItemIcon>
                                <CalendarPlus className={`icon ${mode === 'dark' ? 'dark-md' : ''}`} />
                            </ListItemIcon>
                            <ListItemText primary="Planning" />
                        </ListItemButton>

                </ListItem>

                {/* Dynamically render subpages */}
                {Object.keys(subpages).map((subpageKey) => (
                    <ListItem key={subpageKey} disablePadding>
                            <ListItemButton className="navlink" component={NavLink} to={`/${currentPageKey}/${subpageKey}`}>
                                <ListItemIcon>
                                    <ChartNoAxesCombined className={`icon ${mode === 'dark' ? 'dark-md' : ''}`} />
                                </ListItemIcon>
                                <ListItemText primary={subpageKey} />
                            </ListItemButton>

                    </ListItem>
                ))}
            </List>
        );
    };
    return (
        <div className="games-list">
            <div className="header">
                {/* Uncomment if you want to display the logo */}
                {/* <img className="denda-games" src={Denda} alt="Denda's games" /> */}
            </div>
            <div className="content">
                <div className="searchbar"></div>
                <ul className="game-name-container">
                    {/* Map over structuredPages and render each game name */}
                    {Object.keys(structuredPages).map((gameName) => {
                        if (gameName.toLowerCase() === 'administration') {
                            return null; // Skip the 'administration' page
                        }
                        return (
                            <li key={gameName} className="game-names" onClick={() => handleToggleDrawer(gameName)}>
                                <h2>{gameName}</h2>
                                <ChevronRight/>
                            </li>
                        );
                    })}
                </ul>
            </div>
            {/* Render the drawer */}
            <Drawer className="subpage-drawer" open={open} onClose={handleCloseDrawer} anchor={"bottom"}>
                <div className="drawer-content">
                    <h2 className="drawer-heading">{currentPageKey}</h2>
                    <Divider className="drawer-divider"/>
                    <DrawerList className=" drawer-list"/>
                </div>
            </Drawer>
        </div>
    );
}

export default GamePage;
